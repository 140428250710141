import { AccountServiceBase } from "config";
import { checkResponseErrors, makeRequestInit } from '../fetchHelper';
import { CustomerData } from "./CustomerData";

/**
 * @typedef { import('../auth/Authentication').Authentication } Authentication
 */

export class CustomerEndpoints {
    /**
     *
     * @param {Authentication} auth
     */
    constructor(auth) {
        this._auth = auth;
    }

    _dofetch(url, init) {
        return this._auth
            .fetch(AccountServiceBase + url, init)
            .then(checkResponseErrors);
    }

    getCustomer() {
        const init = makeRequestInit('GET');
        return this._dofetch('/customer', init)
            .then(rsp => rsp.json())
            .then(json => new CustomerData(json));
    }
}
