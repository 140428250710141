export class CustomerData {
    constructor(json) {
        if (!json) return;

        this.CustomerId = json.CustomerId;
        this.Name = json.Name;
        this.PublikEnabled = json.PublikEnabled;
        this.TromanEnabled = json.TromanEnabled;
        this.ValmanEnabled = json.ValmanEnabled;
        this.IsSSOEnabled = json.IsSSOEnabled;
    }
}
